import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
  scmAuthApiRef,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  gitlabAuthApiRef,
} from '@backstage/core-plugin-api';

createApiFactory({
  api: scmAuthApiRef,
  deps: {
    gitlabAuthApi: gitlabAuthApiRef,
  },
  factory: ({ gitlabAuthApi }) => ScmAuth.forGitlab(gitlabAuthApi),
});

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),
];
